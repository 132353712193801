import { lazy, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { YMInitializer } from 'react-yandex-metrika'

import styles from './Main.module.scss'

const Header = lazy(() => import('@/components/Header/Header'))
const TopPage = lazy(() => import('@/components/TopPage/TopPage'))
const About = lazy(() => import('@/components/About/About'))
const TwoDays = lazy(() => import('@/components/TwoDays'))
const Documents = lazy(() => import('@/components/Documents/Documents'))
const Corp = lazy(() => import('@/components/Corp/Corp'))
const Maps = lazy(() => import('@/components/Maps/Maps'))
const FoodPointsScheme = lazy(() => import('@/components/FoodPointsScheme/FoodPointsScheme'))
const MarathonSignup = lazy(() => import('@/components/MarathonSignup/MarathonSignup'))
const Program = lazy(() => import('@/components/Program/Program'))
const Comfort = lazy(() => import('@/components/Comfort/Comfort'))
const Photos = lazy(() => import('@/components/Photos/Photos'))
const Formats = lazy(() => import('@/components/Formats/Formats'))
// const Engraving = lazy(() => import('@/components/Engraving/Engraving'))
const Places = lazy(() => import('@/components/Places/Places'))
const Tshirt = lazy(() => import('@/components/Tshirt/Tshirt'))
// const RunnersHandouts = lazy(() => import('@/components/RunnersHandouts/RunnersHandouts'))
const Excursions = lazy(() => import('@/components/Excursions/Excursions'))
const Pacemaker = lazy(() => import('@/components/Pacemaker/Pacemaker'))
const Banner = lazy(() => import('@/components/Banner/Banner'))
const Support = lazy(() => import('@/components/Support/Support'))
const Faq = lazy(() => import('@/components/Faq/Faq'))
const Partners = lazy(() => import('@/components/Partners/Partners'))
const Footer = lazy(() => import('@/components/Footer/Footer'))

function Main({ formats, city, banners }) {
  const location = useLocation()

  useEffect(() => {
    const handleLoad = () => {
      const anchor = location.hash.slice(1)
      const element = document.getElementById(anchor)
      if (element) {
        element.scrollIntoView()
      }
    }

    window.addEventListener('load', handleLoad)

    if (document.readyState === 'complete') {
      handleLoad()
    }

    return () => {
      window.removeEventListener('load', handleLoad)
    }
  }, [location])

  return (
    <>
      <YMInitializer accounts={[93119132]} options={{ webvisor: true }} />
      <div className={styles.bgTop}>
        <Header />
        <TopPage />
        <About />
      </div>
      <TwoDays />
      <Documents city={city} />
      <div className={styles.bg1}>
        <Corp />
        <Maps city={city} />
      </div>
      {/* <FoodPointsScheme /> */}
      <div className={styles.bg2}>
        <MarathonSignup />
        <Program />
      </div>
      <Comfort />
      <Photos />
      <Formats formats={formats} />
      {/*<Engraving />*/}
      <Tshirt />
      {/*<RunnersHandouts />*/}
      <div className={styles.bg3}>
        <Places />
        <Excursions />
      </div>
      <Pacemaker />
      {/* <Banner /> */}
      <Support />
      {/* <Faq /> */}
      <Partners banners={banners} />
      <Footer />
    </>
  )
}

export default Main
